<template>
  <div class="policy-consent-container">
    <InputCheckbox
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      :id="'policy-consent'"
      :name="'policy-consent'"
      :value="modelValue"
      :checked="modelValue"
      class="clear-reset-styles checkbox-input"
    />

    <label for="policy-consent">
      <p>
        Akceptuję
        <router-link
          :to="{ name: ROUTE_DOCS_TERMS_AND_CONDITIONS_NAME }"
          target="_blank"
        >
          regulamin
        </router-link>
        i
        <router-link
          :to="{ name: ROUTE_DOCS_PRIVACY_POLICY_NAME }"
          target="_blank"
        >
          politykę prywatności
        </router-link>
      </p>
    </label>
  </div>
</template>

<script>
import InputCheckbox from "@/components/UI/Base/InputCheckbox.vue";
import {
  ROUTE_DOCS_PRIVACY_POLICY_NAME,
  ROUTE_DOCS_TERMS_AND_CONDITIONS_NAME,
} from "@/router/constants";

export default {
  components: {
    InputCheckbox,
  },

  props: {
    modelValue: {
      type: Boolean,
    },
  },

  emits: ["update:modelValue"],

  setup() {
    return {
      ROUTE_DOCS_PRIVACY_POLICY_NAME,
      ROUTE_DOCS_TERMS_AND_CONDITIONS_NAME,
    };
  },
};
</script>

<style scoped>
.policy-consent-container {
  display: flex;
  column-gap: 20px;
  cursor: pointer;
}
.checkbox-input {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

@media (max-width: 600px) {
  .policy-consent-container {
    column-gap: 20px;
  }
  .checkbox-input {
    width: 50px;
    height: 50px;
  }
}
</style>
