<template>
  <form class="form">
    <div class="inputs">
      <InputTextNameFirstValidation
        v-model="nameFirst"
        @update:is-valid-name-first="isValidNameFirst = $event"
      />
      <div class="inputs__zip-container">
        <InputTextZipValidation
          v-model="zip"
          @update:is-valid-zip="isValidZip = $event"
        />
      </div>
    </div>

    <CheckboxPolicyConsent v-model="policyConsent" />

    <ButtonPrimaryDone
      @click="$emit('signup')"
      :isDisabled="isButtonDisabled"
    />
  </form>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { useUserProfileStore } from "@/stores/userProfile";
import { storeToRefs } from "pinia";

import InputTextNameFirstValidation from "@/components/UI/InputTextNameFirstValidation.vue";
import InputTextZipValidation from "@/components/UI/InputTextZipValidation.vue";
import ButtonPrimaryDone from "@/components/UI/ButtonPrimaryDone.vue";
import CheckboxPolicyConsent from "./CheckboxPolicyConsent.vue";

export default {
  components: {
    InputTextNameFirstValidation,
    InputTextZipValidation,
    ButtonPrimaryDone,
    CheckboxPolicyConsent,
  },

  emits: ["signup"],

  setup() {
    const userProfileStore = useUserProfileStore();
    const { nameFirst, zip } = storeToRefs(userProfileStore);

    const state = reactive({
      isValidNameFirst: false,
      isValidZip: false,
      policyConsent: false,
    });

    const isButtonDisabled = computed(() => {
      if (state.isValidNameFirst && state.isValidZip && state.policyConsent) {
        return false;
      }
      return true;
    });

    return {
      nameFirst,
      zip,
      ...toRefs(state),
      isButtonDisabled,
    };
  },
};
</script>

<style scoped>
.form {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  row-gap: 60px;
}
.inputs {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}
.inputs__zip-container {
  width: 100%;
}

@media (max-width: 1200px) {
  .form {
    row-gap: 80px;
  }
}
</style>
